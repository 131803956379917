<template>
  <div class="card">
    <div class="card-body">
      <div class="card-header card-header-divider">
        <div class="card-title">Список подписок</div>
      </div>
      <div class="tools">
        <button
            type="button"
            @click="() => router().push('/subscription/edit')"
            class="btn btn-accent btn-sm"
        >
          Создать
        </button>
      </div>
      <div v-if='isLoaded'>
        <div v-if="shownData.length">
          <base-table
              :columns="subscriptionFields"
              :rows="shownData"
          />

          <table-pagination
              v-if="shownData.length"
              :totalPages="1"
              :countOnPage="countOnPage"
              :manual="true"
              :total="subscriptionRows.length"
              :count="countOnPage > shownData.length ? shownData.length : countOnPage"
              @changePage="changePage"
          />
        </div>
        <div v-else class="no_results">
          <p>Нет результатов</p>
        </div>
      </div>
      <div v-else class="table-preloader">
        <preloader/>
      </div>

    </div>

  </div>
</template>

<script>
import BaseTable from "@/components/Tables/BaseTable.vue";
import Preloader from "@/components/Technical/Preloader.vue";
import {onBeforeMount, ref} from "@vue/runtime-core";
import {useStore} from "vuex";
import {computed} from "@vue/reactivity";
import router from "@/router";
import useCheckRights from "@/mixins/useCheckRights";
import TablePagination from "@/components/OtherElements/TablePagination.vue";

export default {
  name: "SubscriptionList.vue",
  methods: {
    router() {
      return router
    }
  },
  components: {TablePagination, Preloader, BaseTable},
  setup() {

    const countOnPage = ref(15);
    const store = useStore();
    const isLoaded = ref(false);
    const { getRight } = useCheckRights();
    const subscriptionList = computed(() => store.state.subscription.subscriptionList);
    const subscriptionRows = computed(() => subscriptionList.value.map((sub) => {
          return {
            id: sub.id,
            title: sub.title,
            entities: sub.products,
            duration_months: sub.duration_months,
          }
    }));
    const subscriptionFields = [
      {
        field: "title",
        headerName: "Название подписки",
      },
      {
        field: "products",
        headerName: "Состав",
      },
      {
        field: "duration_months",
        headerName: "Срок действия подписки (в месяцах)",
      },
      {
        field: "edit",
        headerName: "",
        dest: "subscription",
        res: "edit",
      },
    ];

    const shownData = computed(() => {
      return subscriptionRows.value ? subscriptionRows.value.slice(pageDataStart.value, pageDataStart.value + countOnPage.value) : [];
    });

    const pageDataStart = ref(0);

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    onBeforeMount(() => {
      store.dispatch("profile/getProfile").then(() => {
        if (!getRight('superadmin')) {
          router.push('/');
        }
      });
      store.dispatch("subscription/getSubscriptionList").then(() => {
            isLoaded.value = true;
          }
      ).catch(() => {
        isLoaded.value = true;
      });
    });

    return {
      subscriptionFields,
      shownData,
      subscriptionRows,
      changePage,
      countOnPage,
      isLoaded,
    }
  }
}
</script>

<style scoped lang="scss">
.tools {
  display: flex;
  justify-content: flex-end;
}

.no_results {
  padding: 50px;
  text-align: center;
}

.card-body {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.card-header {
  margin: 0;
}
</style>